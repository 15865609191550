import { memo, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { getImageSrc } from './helpers';

export interface IImageTooltip {
  size?: string; // 36px
  round?: string; // 50%
  placement?: string; // bottom
}

export const ImageTooltip = memo(
  ({ config, src, title }: { config?: IImageTooltip; src?: string; title?: string }) => {
    const src_ = useMemo(() => getImageSrc(src), [src]);
    const settings = useMemo(
      () => ({
        size: '40px',
        round: '50%',
        placement: 'bottom',
        ...(config || {}),
      }),
      [config],
    );
    if (!src) {
      return (
        <div className={'image-tooltip'}>
          <div
            className="no-image"
            style={{
              width: settings.size,
              height: settings.size,
              borderRadius: settings.round,
            }}
          >
            <ImageSearchIcon />
          </div>
          <span>{title}</span>
        </div>
      );
    } else {
      return (
        <div className={'image-tooltip'}>
          <Tooltip
            // @ts-ignore
            placement={settings.placement}
            title={
              <div className={'image-tooltip-content'} style={{ maxWidth: '300px' }}>
                <img alt={'tooltip'} src={src_} style={{ display: 'block', width: '100%' }} />
                <strong>{title}</strong>
              </div>
            }
          >
            <div
              className="avatar"
              style={{
                backgroundImage: `url("${src_}")`,
                width: settings.size,
                height: settings.size,
                borderRadius: settings.round,
              }}
            />
          </Tooltip>
          <span title={title}>{title}</span>
        </div>
      );
    }
  },
);

export default ImageTooltip;
