import {useCallback, useMemo, useState} from "react";

export interface UseOpenProps {
  init: boolean
}

export const useOpen = (props?: UseOpenProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props?.init || false)
  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])
  const handleToggle = useCallback(() => {
    setIsOpen((prev) => {
      return !prev
    })
  }, [])

  return useMemo(() => {
    return {
      isOpen,
      setIsOpen,
      handleOpen,
      handleClose,
      handleToggle
    }
  }, [isOpen, setIsOpen, handleOpen, handleClose, handleToggle])
}
