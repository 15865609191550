import {IUseFormRequestSaveParser} from "../interfaces";
import {reduce} from "lodash";
import {api} from "../../../helpers";

export const parseMixins: IUseFormRequestSaveParser = (data) => {
  return reduce(data, (result: any, value: any, key) => {
    if (value && typeof value === 'object' && !(value instanceof Date)) {
      const {mixin_} = value;
      if (mixin_) {
        result.mixins.push(mixin_);
        if (mixin_.updateModel) result.data[key] = '';
      }
    } else {
      result.data[key] = value;
    }
    return result;
  }, {data: {}, mixins: []});
};

export const saveMixins = async (data: any, mixins: any[]) => {
  let result = {...data};
  for (const mixin of mixins) {
    for (const request of mixin.requests) {
      const {data: data_} = await api(request(data));
      if (mixin.updateModel) {
        result[mixin.name] = data_ ? (typeof data_ === 'object') ? data_[mixin.updateModel] || '' : data_ || '' : '';
      }
    }
  }
  return result;
};
