import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

export const getKey = (path: string) => path.slice(0, path.indexOf('/'));
export const Source = (path: string, fieldID = 'id', mapping = false) => {
  const dispatch = useDispatch();
  const default_ = useMemo(() => ({ loading: true, data: [], map: {} }), []);
  const key = useMemo(() => getKey(path), [path]);
  useEffect(() => {
      dispatch({ type: 'SOURCE_INIT', key, path, fieldID, mapping });
      return () => {
        dispatch({ type: 'SOURCE_MOUNT_UNMOUNT', key, path, methodType: -1 });
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path],
  );
  return useSelector(state => {
    const state_ = (state as any).sources;
    return state_
      ? state_[key]
        ? (state_)[key][path] || default_
        : default_
      : default_;
  }, shallowEqual);
};

// @ts-ignore
export const useSource = <T = { id: string, title: string }>(path: string, fieldID: keyof T = 'id', mapping: boolean = false): { refresh: (() => void), loading: boolean, data: T[], map: Record<string, T | undefined> } => {
  const dispatch = useDispatch();
  const default_ = useMemo(() => ({ loading: true, data: [], map: {} }), []);
  const key = useMemo(() => getKey(path), [path]);

  useEffect(() => {
      dispatch({ type: 'SOURCE_INIT', key, path, fieldID, mapping });
      return () => {
        dispatch({ type: 'SOURCE_MOUNT_UNMOUNT', key, path, methodType: -1 });
      };
    },
    [key, path, fieldID, mapping, dispatch],
  );

  const refresh = useCallback(() => {
    dispatch({ type: 'SOURCES_UPDATE', key, path });
  }, [key, path, dispatch]);

  const current = useSelector((state) => {
    const sources = (state as any).sources;
    return sources[key] && sources[key][path];
  });
  const result = current || default_;

  const map = useMemo(() => {
    if (!mapping) {
      return {};
    }
    return result.data.reduce((accum: Record<string, T>, item: T) => {
      const key = String(item[fieldID]);
      accum[key] = item;
      return accum;
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data, mapping, fieldID]);
  return useMemo(() => {
    return {
      ...result,
      refresh,
      map,
    };
  }, [result, refresh, map]);
};
export default Source;
