import { memo } from 'react';
import { Button } from '@mui/material';
import CopyPathToClipboard from '../../copy-path-to-clipboard';
import { useI18n } from '../../i18';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';

export interface IControlsProps {
  state: 'create' | 'save';
  onCancel?: () => void;
  onSubmit?: (props: any) => void;
  onSubmitAndContinue?: (props: any) => void;
  onDelete?: () => void;
  loading?: boolean;
  showCopy?: boolean;
  showSubmit?: boolean;
  showSubmitAndContinue?: boolean;
  showDelete?: boolean;
  showCancel?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const Controls = memo(
  ({
    state,
    onCancel,
    onSubmit,
    onSubmitAndContinue,
    onDelete,
    loading = false,
    showCopy = true,
    showSubmit = true,
    showSubmitAndContinue = false,
    showDelete = true,
    showCancel = true,
    size = 'small',
  }: IControlsProps) => {
    const { t } = useI18n();
    return (
      <div className="form-edit-controls">
        {state === 'save' && showDelete ? (
          <div className="left-side">
            <Button
              className="btn-delete"
              variant="contained"
              color="secondary"
              size={size}
              onClick={onDelete}
              disabled={loading}
              startIcon={<DeleteIcon />}
            >
              {t('delete')}
            </Button>
          </div>
        ) : (
          <div />
        )}
        <div className="right-side">
          {state === 'save' && showCopy && (
            <CopyPathToClipboard text={window.location.href}>
              <Button
                className="btn-copy-path"
                color="secondary"
                size={size}
                startIcon={<LinkIcon />}
                disabled={loading}
              >
                {t('copy')}
              </Button>
            </CopyPathToClipboard>
          )}
          {showCancel && (
            <Button
              className="btn-cancel"
              onClick={onCancel}
              color="secondary"
              size={size}
              disabled={loading}
            >
              {t(state === 'save' ? 'cancel' : 'back')}
            </Button>
          )}
          {state === 'create' && showSubmitAndContinue && (
            <Button
              className="btn-create-and-continue"
              variant="contained"
              color="primary"
              size={size}
              onClick={onSubmitAndContinue}
              disabled={loading}
            >
              {t('create-and-continue')}
            </Button>
          )}
          {showSubmit && (
            <Button
              className="btn-save-and-create"
              variant="contained"
              color="primary"
              size={size}
              onClick={onSubmit}
              disabled={loading}
            >
              {t(state)}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

export default Controls;
