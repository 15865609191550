import { OptionsObject, VariantType } from 'notistack';
import { getNewKey } from '../../components';
import { NotifierActionClose } from '../../components';
// const
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
// state
const defaultState: {
  notifications: OptionsObject[];
} = {
  notifications: [],
};
// reducer
export default (
  state = defaultState,
  action: {
    type: string;
    key?: string;
    notification?: OptionsObject;
    dismissAll?: boolean;
  },
) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [...state.notifications, { ...action.notification }],
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          return action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification };
        }),
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
};
// actions
export const enqueueSnackbar = ({
  message,
  options,
}: {
  message: string;
  options: OptionsObject;
}) => {
  const key = options.key || getNewKey();
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      message,
      key: key,
      options: { ...options, key: key },
    },
  };
};
export const closeSnackbar = (key?: string | number) => {
  return {
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  };
};
export const removeSnackbar = (key: string | number) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const notifyRequestResult = (
  message = 'success',
  variant: VariantType = 'success',
  options: any = {},
) => {
  return enqueueSnackbar({
    message: message,
    options: {
      variant,
      ...options,
      action: (key) => <NotifierActionClose notifierKey={key} />,
    },
  });
};
